import { Observable } from 'rxjs';
import { ISbStoriesParams } from 'storyblok-js-client';

import { Injectable } from '@angular/core';

import { BlogpostDto, BlogpostItemsDto } from '@cgib/shared/dtos/storyblok/blogpost.dto';
import { FaqpageDto } from '@cgib/shared/dtos/storyblok/faqpage.dto';
import { FeaturepageDto, FeaturepageItemsDto } from '@cgib/shared/dtos/storyblok/feature-page.dto';
import { NavigationpageDto } from '@cgib/shared/dtos/storyblok/navigation-page.dto';
import { StoryblokGraphqlService } from '@cgib/ui-shared/services/storyblok-graphql/stroyblok-graphql.service';

@Injectable({
    providedIn: 'root'
})
export class StoryblokService {
    constructor(private readonly graphqlService: StoryblokGraphqlService) {}

    getBlogPost$(slug: string, forceFetch = false): Observable<BlogpostDto> {
        return this.graphqlService.getBlogPost$(slug, forceFetch);
    }

    getBlogPosts$(params: ISbStoriesParams, signal?: AbortSignal): Observable<BlogpostItemsDto> {
        return this.graphqlService.getBlogPosts$(params, signal);
    }

    getFaq$(id: string): Observable<FaqpageDto> {
        return this.graphqlService.getFaqPage$(id);
    }

    getFeature$(slug: string, forceFetch = false): Observable<FeaturepageDto> {
        return this.graphqlService.getFeaturePage$(slug, forceFetch);
    }

    getFeatures$(params: ISbStoriesParams, signal?: AbortSignal): Observable<FeaturepageItemsDto> {
        return this.graphqlService.getFeaturePages$(params, signal);
    }

    getNavigations$(slug: string, forceFetch = false): Observable<NavigationpageDto> {
        return this.graphqlService.getNavigationPage$(slug, forceFetch);
    }
}
